import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams,  } from 'react-router-dom';

// Mui Imports
import { Grid, Typography, Card, CardContent, TextField, Divider, Button, MenuItem, Tooltip, IconButton } from '@mui/material';
import Breadcrumb from 'component/Breadcrumb';
import LoadingOverlay from 'component/LoadingOverlay';
// import CustomTable from 'component/CustomTable';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';
import { deactiveArrayUtil } from 'utils/deactiveArrayUtil';
import CustomTable from 'component/CustomTable';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { formatDate } from 'utils/FormatUtils';

const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

const AirportDetails = () => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const navigate = useNavigate();
  const { airportId, companyId } = useParams();

  const policyColumns = [
    {
      id: 'policyNumber',
      label: 'Policy Number'
    },
    {
      id: 'policyStatus',
      label: 'Policy Status'
    },
    {
      id: 'agentDisplayName',
      label: 'Agents Display Name'
    }
  ];

  const [airport, setAirport] = useState({});
  const [companyUsers, setCompanyUsers] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [users, setUsers] = useState([]);
  const [agencyId, setAgencyId] = useState(null);
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [policyPagination, setPolicyPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 25
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'idAgency') {
      setAgencyId(value);
      setAirport({
        ...airport,
        idAgent: '',
        [name]: value
      });
    } else {
      setAirport({
        ...airport,
        [name]: value
      });
    }
  };

  const handleOnBlurAirport = async (event) => {
    const { value } = event.target;
    if (value.trim().length === 3) {
      const response = await axios.get('/api/airport/airportInfo', {
        params: {
          faaCode: value?.toUpperCase()
        }
      });
      setAirport({
        ...airport,
        name: response.data.name,
        cityName: response.data.cityName,
        countryName: response.data.countryName,
        countryCode: response.data.countryCode,
        regionCode: response.data.regionCode,
        stateCode: response.data.stateCode,
        longitude: response.data.longitude,
        latitude: response.data.latitude,
        airportType: response.data.airportType,
        county: response.data.county,
        manager: response.data.manager,
        managerPhone: response.data.managerPhone,
        effectiveDate: response.data.effectiveDate
      });
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    const response = await axios.put(`/api/airport/${airportId}/companyId/${companyId}`, {
      airport: {
        idAgency: airport?.idAgency,
        idAgent: airport?.idAgent,
        idCompanyUser: airport?.idCompanyUser,
        code: airport?.code?.toUpperCase(),
        name: airport?.name,
        cityName: airport?.cityName,
        countryName: airport?.countryName,
        countryCode: airport?.countryCode,
        regionCode: airport?.regionCode,
        stateCode: airport?.stateCode,
        longitude: airport?.longitude,
        latitude: airport?.latitude,
        airportType: airport?.airportType,
        county: airport?.county,
        manager: airport?.manager,
        managerPhone: airport?.managerPhone,
        effectiveDate: new Date(airport?.effectiveDate)
      },
      detailsFlag: true
    });
    setAirport({
      ...response.data,
      effectiveDate: formatDate(response.data?.effectiveDate)
    });
    setIsLoading(false);
  };

  const handlePolicyChangeRowsPerChange = (event) => {
    setPolicyPagination({ ...policyPagination, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  const handlePolicyChangePage = async (event, page) => {
    setPolicyPagination({ ...policyPagination, page });
  };

  const handleGoToDetail = (policy) => {
    navigate(`/company/${companyId}/policy/${policy?.id}`);
  };

  useEffect(() => {
    const getAirport = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/airport/${airportId}/companyId/${companyId}`);
      setAirport({ ...response.data, effectiveDate: response?.data?.effectiveDate ? formatDate(response?.data?.effectiveDate) : '' });
      setAgencyId(response.data.idAgency);
      setPolicies([...response.data.Policy]);
      setPolicyPagination({ ...policyPagination, count: response?.data?.Policy?.length });
      setIsLoading(false);
    };

    const getAgencies = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/agency/companyId/${companyId}`);
      setAgencies([...response.data]);
      setIsLoading(false);
    };

    const getAgents = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/agent/companyId/${companyId}`);
      setUsers([...response.data]);
      setIsLoading(false);
    };

    const getCompanyUserProfiles = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/company/${companyId}/users`);
      setCompanyUsers(result.data.UserProfile);
      setIsLoading(false);
    };

    getAirport();
    getAgencies();
    getAgents();
    getCompanyUserProfiles();
  }, [companyId, airportId]);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb title="Airport Details" divider={false} sx={{ mt: { lg: 0, xs: 2 } }}>
            <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
              Home
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/airports`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Airports
            </Typography>
            <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
              Airport Details
            </Typography>
          </Breadcrumb>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <Grid item lg={8} xs={12}>
        <Grid container spacing={gridSpacing} xs={12} item sx={{ flexWrap: { lg: 'nowrap', sm: 'wrap' } }} className="grid-container">
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Card>
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Company Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item lg={4} xs={12}>
                        <Typography variant="subtitle2">
                          {agencies?.length > 0 && (
                            <TextField
                              fullWidth
                              name="idAgency"
                              label="Agency"
                              variant="outlined"
                              value={airport?.idAgency || ''}
                              select
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Agency_Adm')
                              }}
                              onChange={handleChange}
                            >
                              {deactiveArrayUtil(agencies, {
                                id: airport?.idAgency,
                                name: airport?.Agency?.name
                              }).map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <Typography variant="subtitle2">
                          {users?.length > 0 && (
                            <TextField
                              fullWidth
                              name="idAgent"
                              label="Agent Name"
                              variant="outlined"
                              value={airport?.idAgent || ''}
                              error={!airport?.idAgent}
                              helperText={!airport?.idAgent ? 'Cannot be Empty' : ''}
                              select
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                              }}
                              onChange={handleChange}
                            >
                              {deactiveArrayUtil(users, {
                                id: airport?.idAgent,
                                displayName: airport?.Agent?.displayName
                              })
                                ?.filter((item) => item.idAgency === agencyId)
                                .map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.displayName}
                                  </MenuItem>
                                ))}
                            </TextField>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <Typography variant="subtitle2">
                          {companyUsers?.length > 0 && (
                            <TextField
                              fullWidth
                              name="idCompanyUser"
                              label="Company User"
                              variant="outlined"
                              value={airport?.idCompanyUser || ''}
                              select
                              InputProps={{
                                readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                              }}
                              onChange={handleChange}
                            >
                              {deactiveArrayUtil(companyUsers, {
                                id: airport?.idCompanyUser,
                                displayName: airport?.UserProfile?.displayName
                              }).map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.displayName}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item sm={4} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Airport Name"
                          variant="outlined"
                          value={airport?.name || ''}
                          error={!airport?.name}
                          helperText={!airport?.name && 'Required'}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          name="code"
                          label="FAA Code"
                          variant="outlined"
                          value={airport?.code || ''}
                          error={airport?.code?.trim()?.length !== 3}
                          helperText={airport?.code?.trim()?.length !== 3 ? 'Must be 3 characters long' : ''}
                          onBlur={handleOnBlurAirport}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Airport Type"
                          variant="outlined"
                          name="airportType"
                          value={airport?.airportType || ''}
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item lg={3} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Country Name"
                          variant="outlined"
                          name="countryName"
                          value={airport?.countryName || ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={3} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="State"
                          variant="outlined"
                          name="stateCode"
                          value={airport?.stateCode || ''}
                          error={!airport?.stateCode}
                          helperText={!airport?.stateCode && 'Required'}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={3} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="City Name"
                          variant="outlined"
                          name="cityName"
                          value={airport?.cityName || ''}
                          error={!airport?.cityName}
                          helperText={!airport?.cityName && 'Required'}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={3} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="County Name"
                          variant="outlined"
                          name="county"
                          value={airport?.county || ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Geographic Location
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Longitude"
                          variant="outlined"
                          name="longitude"
                          value={airport?.longitude || ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Latitude"
                          name="latitude"
                          variant="outlined"
                          value={airport?.latitude || ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item sm zeroMinWidth>
                    <Typography component="div" variant="h5">
                      Owner Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item lg={4} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Manager Name"
                          variant="outlined"
                          name="manager"
                          value={airport?.manager || ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Manager Phone"
                          variant="outlined"
                          name="managerPhone"
                          value={airport?.managerPhone || ''}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <TextField
                          sx={{ mt: 1 }}
                          fullWidth
                          label="Effective Date"
                          name="effectiveDate"
                          variant="outlined"
                          value={airport?.effectiveDate || ''}
                          onChange={handleChange}
                          error={airport?.effectiveDate?.trim().length > 0 ? !dateRegex.test(airport?.effectiveDate) : false}
                          InputProps={{
                            readOnly: !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }}
                          helperText={
                            airport?.effectiveDate?.trim().length > 0
                              ? !dateRegex.test(airport?.effectiveDate) && 'Incorrect Format mm/dd/yyyy'
                              : ''
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                      <Grid item sm={6} md={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSave}
                          disabled={
                            !airport?.idAgent ||
                            !airport?.code ||
                            !airport?.name ||
                            !airport?.cityName ||
                            !airport.stateCode ||
                            (airport?.effectiveDate?.trim().length > 0 && !dateRegex.test(airport?.effectiveDate)) ||
                            !auth.writePrivledge(auth.user, 'Airport_Adm')
                          }
                        >
                          Save Changes
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <CustomTable
                  columns={policyColumns}
                  data={policies.slice(
                    policyPagination?.page * policyPagination?.rowsPerPage,
                    (policyPagination?.page + 1) * policyPagination?.rowsPerPage
                  )}
                  isLoading={isLoading}
                  hasPagination={true}
                  pagination={policyPagination}
                  handleChangePage={handlePolicyChangePage}
                  handleChangeRowsPerChange={handlePolicyChangeRowsPerChange}
                  title="Policy"
                >
                  {(row) => (
                    <>
                      <Tooltip title="Policy Details">
                        <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row)}>
                          <TextSnippetIcon fontSize='large'/>
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </CustomTable>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AirportDetails;
