import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import initializeAxios from 'utils/axios';

// material-ui
import { Typography, IconButton, Tooltip } from '@mui/material';
import useAuth from 'hooks/useAuth';
import CustomTable from 'component/CustomTable';
// project import
import Breadcrumb from 'component/Breadcrumb';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LoadingOverlay from 'component/LoadingOverlay';
import AirportDialog from 'component/AirportDialog';

// ==============================|| CONTACT LIST ||============================== //

const Airports = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const axios = initializeAxios(auth);

  const columns = [
    {
      id: 'name',
      label: 'Airport Name'
    },
    {
      id: 'code',
      label: 'IATA Code'
    },
    {
      id: 'agencyName',
      label: 'Agency Name'
    },
    {
      id: 'agentName',
      label: 'Agent Display Name'
    }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 25
  });
  const [airports, setAirports] = useState([]);
  const [airportId, setAirportId] = useState([]);
  const { companyId } = useParams();
  const [actionType, setActionType] = useState('');
  const [airport, setAirport] = useState({});
  const handleClickOpendialog = () => {
    setActionType('Create');
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
    setAirport({});
  };

  const handleChangePage = async (event, page) => {
    setPagination({ ...pagination, page });
  };

  const handleSave = async (airportDialog) => {
    setIsLoading(true);
    switch (actionType) {
      case 'Create': {
        const response = await axios.post(`/api/airport/companyId/${companyId}`, {
          airport: {
            idAgency: airportDialog?.idAgency,
            idAgent: airportDialog?.idAgent,
            idCompanyUser: airportDialog?.idCompanyUser,
            code: airportDialog?.code?.toUpperCase()
          },
          detailsFlag: false
        });
        handleGoToDetail(response.data);

        break;
      }

      case 'Edit': {
        const response = await axios.put(`/api/airport/${airportId}/companyId/${companyId}`, {
          airport: {
            idAgency: airportDialog?.idAgency,
            idAgent: airportDialog?.idAgent,
            idCompanyUser: airportDialog?.idCompanyUser,
            code: airportDialog?.code?.toUpperCase()
          },
          detailsFlag: false
        });
        const tempArray = [...airports];
        const index = tempArray.findIndex((e) => e.id === airportId);
        tempArray[index] = {
          ...response.data
        };
        setAirports(tempArray);

        break;
      }

      case 'Delete': {
        await axios.delete(`/api/airport/${airportId}/companyId/${companyId}`);
        setPagination({ ...pagination, page: 0, count: pagination?.count - 1 });
        const tempArray = [...airports];
        const index = tempArray.findIndex((e) => e.id === airportId);
        tempArray.splice(index, 1);
        setAirports(tempArray);
        handleChangePage(null, 0);
        break;
      }
    }
    handleClosedialog();
    setIsLoading(false);
  };

  const handleEdit = (editAirport) => {
    const newAircraft = {
      idAgent: editAirport.idAgent,
      idAgency: editAirport?.idAgency,
      idCompanyUser: editAirport?.idCompanyUser,
      code: editAirport?.code?.toUpperCase()
    };
    setAirport(newAircraft);
    setActionType('Edit');
    setAgencyId(editAirport?.idAgency);
    setAirportId(editAirport.id);
    setOpen(true);
  };

  const handleDelete = (deleteAirport) => {
    setAirportId(deleteAirport.id);
    setActionType('Delete');
    setOpen(true);
  };

  const handleChangeRowsPerChange = (event) => {
    setPagination({ ...pagination, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  const handleGoToDetail = (airportDetails) => {
    navigate(`/company/${companyId}/airports/${airportDetails.id}`);
  };

  useEffect(() => {
    const getAirports = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/airport/companyId/${companyId}`, {
        params: { page: pagination.page, rowsPerPage: pagination.rowsPerPage }
      });
      setAirports(result.data?.airports);
      setPagination({ ...pagination, count: result?.data?.count });
      setIsLoading(false);
    };

    getAirports();
  }, [companyId, pagination.page, pagination.rowsPerPage]);

  return (
    <>
      <Breadcrumb title="Airports" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Airports
        </Typography>
      </Breadcrumb>
      <LoadingOverlay loading={isLoading} />
      <CustomTable
        title="Airports"
        isLoading={isLoading}
        data={airports}
        columns={columns}
        hasPagination={true}
        pagination={pagination}
        hasCreateButton={true}
        handleOpenCreate={handleClickOpendialog}
        handleChangePage={handleChangePage}
        handleChangeRowsPerChange={handleChangeRowsPerChange}
        isDisabled={!auth.writePrivledge(auth.user, 'Airport_Adm')}
      >
        {(row) => (
          <>
            <Tooltip title="Edit Airport" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Airport_Adm')}
                  onClick={() => handleEdit(row)}
                >
                  <EditTwoToneIcon fontSize='large'/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Airport" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Airport_Adm')}
                  onClick={() => handleDelete(row)}
                >
                  <DeleteIcon fontSize='large'/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Airport Details">
              <IconButton color="secondary" aria-label="Edit" size="large" onClick={() => handleGoToDetail(row)}>
                <TextSnippetIcon fontSize='large'/>
              </IconButton>
            </Tooltip>
          </>
        )}
      </CustomTable>
      <AirportDialog
        data={airport}
        handleClosedialog={handleClosedialog}
        handleAirportSave={handleSave}
        actionType={actionType}
        open={open}
      />
    </>
  );
};

export default Airports;
